body {
  margin: 0;
  min-width: 768px;
  background-color: #354649;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-container {
  height: 0 !important;
  max-width: 100% !important;
  background-color: rgba(0,0,0,0) !important;
}

.nav-toggler {
  transition: all .3s ease-in-out;
  border-color: rgba(0,0,0,0) !important;
  opacity: 0.5 !important;
}

.nav-toggler:hover, .nav-toggler.active, .bubble-btn.nav-link {
  opacity: 1 !important;
  box-shadow: 0 0 6px rgba(0,0,0,0.3) !important;
}

.bubble-btn {
  transition: all .3s ease-in-out;
  text-decoration: none !important;
  background-color: #333 !important;
  color: #BBB !important;
  border-radius: 200px !important;
  border-width: 2px !important;
  border-color: rgba(0,0,0,0) !important;
  border-style: solid !important;
  box-shadow: none !important;
}

.bubble-btn:hover, .bubble-btn.active {
  color: #FFF !important;
  border-color: #FFF !important;
}

.parallax-bg {
  background-image: url('./assets/cover.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: scale(1.3);
}

.card-header, .card-img-top {
  background-color: #333 !important;
  color: #FFF !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.card-body {
  background-color: #FFF !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.project-card-body {
  background-color: #333 !important;
  color: #FFF !important;
  border-radius: 0 !important;
  border: none !important;
}

.card-footer {
  background-color: #474747 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: none !important;
}

.card {
  border: none !important;
  background-color: rgba(0,0,0,0) !important;
  border-radius: 10px !important;
  box-shadow: 0 0 25px rgba(0,0,0,0.3);
}

.project-card {
  border: none !important;
  background-color: rgba(0,0,0,0) !important;
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
